// ref: https://stackoverflow.com/questions/68813736/use-the-same-gltf-model-twice-in-react-three-fiber-drei-three-js
// ref: https://codesandbox.io/s/re-using-gltfs-dix1y

import React, { useMemo, useEffect, useLayoutEffect, useState, useRef } from 'react'
import { useLoader, useFrame } from '@react-three/fiber';
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { Color, AnimationMixer } from 'three';


export default function Model({ position, rotation, scale, gltf_path, color_hex, enable_animation }) {
    const { scene, materials, animations } = useLoader(GLTFLoader, gltf_path)
    const copiedScene = useMemo(() => scene.clone(), [scene])

    // TODO: assign color to model which got copied within the same `.gltf` model file
    // set color for 3D model
    if (color_hex != null) {
        useEffect(() => {
            if (materials.material != null) {
                Object.assign(materials.material, {
                    roughness: 0,
                    metalness: 0.25,
                    emissive: new Color(0x000000),
                    color: new Color(color_hex),
                    envMapIntensity: 0.5
                });
            }

        }, [copiedScene, color_hex]);
    }

    // play model embeded animation
    if (enable_animation === true) {
        let mixer
        if (animations.length) {
            mixer = new AnimationMixer(copiedScene);
            animations.forEach(clip => {
                const action = mixer.clipAction(clip)
                action.play();
            });
        }

        useFrame((state, delta) => {
            mixer.update(delta)
        })
    }

    return (
        <>
            <mesh position={position} rotation={rotation} scale={scale} >
                <primitive object={copiedScene} scale={1} />
            </mesh>
        </>
    );
}
