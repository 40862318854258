import React from 'react'
import { useLoader } from '@react-three/fiber';
import { TextureLoader } from "three";


const Image = ({ image_path, size, enable_transparent }) => {
    const texture = useLoader(TextureLoader, image_path)
    const opacity = 0.5;

    if (enable_transparent === true) {
        return (
            <mesh rotation={[-3.14/2, 0, 3.14/2]} >
                <planeGeometry attach="geometry" args={size} />
                <meshBasicMaterial attach="material" map={texture} opacity={opacity} transparent />
            </mesh>
        );
    }
    else {
        return (
            <mesh rotation={[-3.14/2, 0, 3.14/2]} >
                <planeGeometry attach="geometry" args={size} />
                <meshBasicMaterial attach="material" map={texture} />
            </mesh>
        );
    }
}

export default function Heatmap({ layer_heatmap, currentHeatmapPath }) {
    if (layer_heatmap === true) {
        return (
            <>
                <mesh position={[0, 2.5, -20]} >
                    <Image image_path={currentHeatmapPath} size={[440, 350]} enable_transparent={true} />
                </mesh>
            </>
        );
    }
    else {
        return (<></>);
    }
}
