// ref: https://github.com/plotly/react-plotly.js
import Plot from 'react-plotly.js';
import React from 'react'


export default function GaugeFigure({ heading, enable_energy, enable_iaq, enable_co2eq, currentCO2 }) {
    var energy_data = (enable_energy && [
        {
            domain: { x: [0, 1], y: [0, 1] },
            value: 1138,
            title: { text: heading, font: { size: 18 } },
            type: "indicator",
            mode: "gauge+number+delta",
            delta: {
                reference: 298,
                increasing: {
                    color: "#FF4136",  // "#3D9970" green
                    symbol: "▼"   // "▲"
                },
                decreasing: {
                    color: "#3D9970",
                    symbol: "▲"
                }
            },
            gauge: {
                axis: {
                    range: [2000, 0],
                    ticktext: ['2000', '1500', '1000', '700', '500', 'BEC', 'ZEB'],
                    tickvals: [2000, 1500, 1000, 700, 500, 298, 126]
                },
                steps: [
                    { range: [0, 126], color: "darkgreen" },
                    { range: [126, 298], color: "rgb(18, 186, 181)" },
                    { range: [700, 2000], color: "rgb(249, 195, 105)" }
                ],
                bar: {
                    color: "#000000",
                }
            },
        }
    ])

    var iaq_data = (enable_iaq && currentCO2 && [
        {
            domain: { x: [0, 1], y: [0, 1] },
            value: currentCO2,
            title: { text: heading, font: { size: 18 } },
            type: "indicator",
            mode: "gauge+number",
            gauge: {
                axis: {
                    range: [400, 2100],
                },
                steps: [
                    { range: [400, 700], color: "green" },
                    { range: [700, 1100], color: "rgb(18, 186, 181)" },
                    { range: [1100, 1600], color: "rgb(249, 195, 105)" },
                    { range: [1600, 2000], color: "rgb(240, 67, 53)" },
                    { range: [2000, 2100], color: "brown" },
                ],
                bar: {
                    color: "#000000"
                }
            },
        }
    ])

    var co2eq_data = (enable_co2eq && [
        {
            domain: { x: [0, 1], y: [0, 1] },
            value: 362.88,
            title: { text: heading, font: { size: 18 } },
            type: "indicator",
            mode: "gauge+number",
            gauge: {
                axis: {
                    range: [0, 400],
                },
                bar: {
                    color: "#000000"
                }
            },
        }
    ])

    const layout = {
        margin: { l: 40, r: 40, t: 10, b: 0, autoexpand: true },
        plot_bgcolor: "rgba(0,0,0,0)", // background color of the chart gauge
        paper_bgcolor: "rgba(0,0,0,0)" // background render area behind chart
    }

    return (
        <div>
            {enable_energy && (
            <Plot 
                data={energy_data}
                layout={layout}
                style={{ width: "100%", height: '200px' }}
            />)}
            {enable_iaq && (
            <Plot 
                data={iaq_data}
                layout={layout}
                style={{ width: "100%", height: '200px' }}
            />)}
            {enable_co2eq && (
            <Plot 
                data={co2eq_data}
                layout={layout}
                style={{ width: "100%", height: '200px' }}
            />)}
        </div>
    );
}