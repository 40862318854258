import React from 'react'
import { useGLTF } from "@react-three/drei";

import Model from './Model';


const gltf_path = './models/snowflake_2/scene.gltf';


export default function SnowflakeIcon({ position, rotation }) {
    var _position = position;
    if (rotation[1] === 0) {
        _position = [position[0]-20, position[1], position[2]];
    }
    else {
        _position = [position[0], position[1], position[2]-20];
    }
    
    return (
        <>
            <mesh position={_position} rotation={rotation} scale={[3, 3, 3]} >
                <Model gltf_path={gltf_path} />
            </mesh>
        </>
    );
}

useGLTF.preload(gltf_path);
