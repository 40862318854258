import React, { useEffect, useState, useRef, useMemo } from 'react'
import { Canvas, useLoader, useThree, useFrame, extend } from '@react-three/fiber';
import { Environment, OrbitControls, useGLTF, Effects } from "@react-three/drei";
import { Color, Vector2, Fog, MeshBasicMaterial } from 'three';
import { EffectComposer, Outline, SelectiveBloom, Bloom } from '@react-three/postprocessing'
import { BlurPass, Resizer, KernelSize } from 'postprocessing'

import Model from './Model';
import Persons from './Person';
import Heatmap from './Heatmap';
import Sensor from './Sensor';
import AC from './AC';

import './style.css'


// load JSON data
const site_name = 'lotus-lasalle'
const date = '2022-11-21'
var person_data = require(`../data/${site_name}/${date}/person.json`)
var heatmap_path_data = require(`../data/${site_name}/${date}/heatmap_path.json`)
var sensor1_data = require(`../data/${site_name}/${date}/sensor1.json`)
var sensor2_data = require(`../data/${site_name}/${date}/sensor2.json`)
var ac1_data = require(`../data/${site_name}/${date}/ac1.json`)
var ac2_data = require(`../data/${site_name}/${date}/ac2.json`)
var ac3_data = require(`../data/${site_name}/${date}/ac3.json`)
var ac4_data = require(`../data/${site_name}/${date}/ac4.json`)
var ac5_data = require(`../data/${site_name}/${date}/ac5.json`)

// preprocess JSON data
const PersonLocations = [];
for (let i = 0; i < person_data.length; i++) {
  let _data = person_data[i];
  let timestamp = _data['timestamp']
  let person_objects = _data['objects']
  let coords = [];
  let person_id = 1;
  for (let j = 0; j < person_objects.length; j++) {
    let _coords = person_objects[j]['coordinates'];
    let _x = (parseInt(_coords[1]) / 462 * 280) - 150;
    let _y = (-1)*((parseInt(_coords[0]) / 920 * 380) - 180);
    coords.push({ x: _x, z: _y, id: String(person_id) });
    if (person_id >= 4) {
      person_id = 4;
    }
    else {
      person_id += 1;
    }
  }
  PersonLocations.push({ 'timestamp': timestamp, 'coords': coords })
}

// main scene controls script
function Scene({ selectedTs, layer_person, layer_heatmap, layer_iot, layer_ai }) {
  const [currentPosition, setCurrentPosition] = useState(PersonLocations[selectedTs])
  const [currentHeatmapPath, setcurrentHeatmapPath] = useState(heatmap_path_data[selectedTs])
  const [currentSensor1, setcurrentSensor1] = useState(sensor1_data[selectedTs])
  const [currentSensor2, setcurrentSensor2] = useState(sensor2_data[selectedTs])
  const [currentAC1, setcurrentAC1] = useState(ac1_data[selectedTs])
  const [currentAC2, setcurrentAC2] = useState(ac2_data[selectedTs])
  const [currentAC3, setcurrentAC3] = useState(ac3_data[selectedTs])
  const [currentAC4, setcurrentAC4] = useState(ac4_data[selectedTs])
  const [currentAC5, setcurrentAC5] = useState(ac5_data[selectedTs])

  useEffect(() => {
    setCurrentPosition(PersonLocations[selectedTs]);
    setcurrentHeatmapPath(heatmap_path_data[selectedTs]);
    setcurrentSensor1(sensor1_data[selectedTs]);
    setcurrentSensor2(sensor2_data[selectedTs]);
    setcurrentAC1(ac1_data[selectedTs]);
    setcurrentAC2(ac2_data[selectedTs]);
    setcurrentAC3(ac3_data[selectedTs]);
    setcurrentAC4(ac4_data[selectedTs]);
    setcurrentAC5(ac5_data[selectedTs]);
    // console.log(selectedTs);
  }, [selectedTs]);

  return (
    <>
      <Canvas camera={{ far: 3000, position: [-150, 350, 150] }} >
        <ambientLight intensity={0.4} />
        <pointLight position={[0, 60, -100]} intensity={0.5} />
        <pointLight position={[0, 60, 100]} intensity={0.5} />
        <pointLight position={[0, 60, 220]} intensity={0.5} />

        <Controls />
        <OrbitControls />

        <Floor />
        <ShopFloor gltf_path='./models/shopfloor/scene.gltf' />

        <Persons layer_person={layer_person} currentPosition={currentPosition} />
        <Heatmap layer_heatmap={layer_heatmap} currentHeatmapPath={currentHeatmapPath} />
        <Sensor layer_iot={layer_iot} position={[-100, 38, 100]} currentSensor={currentSensor1} enable_co2={true} />
        <Sensor layer_iot={layer_iot} position={[37, 60, -115]} currentSensor={currentSensor2} />
        <AC ac_id={1} layer_ai={layer_ai} position={[-90, 70, -230]} rotation={[0, 0, 0]} currentAC={currentAC1} />
        <AC ac_id={3} layer_ai={layer_ai} position={[160, 70, -120]} rotation={[0, -3.14/2, 0]} currentAC={currentAC2} />
        <AC ac_id={4} layer_ai={layer_ai} position={[160, 70, -20]} rotation={[0, -3.14/2, 0]} currentAC={currentAC3} />
        <AC ac_id={5} layer_ai={layer_ai} position={[160, 70, 80]} rotation={[0, -3.14/2, 0]} currentAC={currentAC4} />
        <AC ac_id={2} layer_ai={layer_ai} position={[90, 70, -230]} rotation={[0, 0, 0]} currentAC={currentAC5} />

        <ACCondenser />

      </Canvas>
    </>
  );
}

const ACCondenser = () => {
  return (
    <>
      <mesh position={[-225, 5, -150]} rotation={[0, 0, 0]} scale={[20, 20, 20]} >
        <Model gltf_path={'./models/air_conditioner_condenser/scene.gltf'} enable_animation={true} />
      </mesh>
    </>
  );
}

const ShopFloor = ({ gltf_path }) => {
  return (
    <>
      <Model position={[0, 0, 0]} rotation={[0, 0, 0]} scale={[40, 40, 40]} gltf_path={gltf_path} />
    </>
  );
}

const Floor = () => {
  const {
    scene,
    gl: { shadowMap }
  } = useThree();

  // initial scene setup
  useEffect(() => {
    const directionalLight = scene.children[1];
    scene.background = new Color(0xffffff);
    scene.fog = new Fog(0xffffff, 20, 2000);
    directionalLight.shadow.mapSize = new Vector2(1024, 1024);
    shadowMap.enabled = true;
  }, []);

  return(
    <>
      <mesh position={[0,-1,0]} receiveShadow rotation={[-0.5 * Math.PI, 0, 0]} >
          <planeGeometry args={[4000,4000,1,1]} />
          {/* <meshPhongMaterial color={new Color(0xc0c0c0)} shininess={0} /> */}
          <meshPhongMaterial color={new Color(0x000000)} shininess={0} />
          {/* <hemisphereLight
            skycolor={new Color(0xfffff)}
            groundColor={new Color(0xffffff)}
            intensity={0.6}
            position={[0, 50, 0]}
          /> */}
          {/* <directionalLight
            color={new Color(0xffffff)}
            intensity={0.54}
            position={[-8, 12, 8]}
            castShadow
          /> */}
      </mesh>
    </>
  );
}

const Controls = () => {
  const {
    camera,
    gl: { domElement },
  } = useThree();

  return <OrbitControls args={[camera, domElement]} />;
}

export default Scene;
