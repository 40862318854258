import React, { useState } from 'react'
import { useGLTF } from "@react-three/drei";
import { useFrame } from '@react-three/fiber';
import { Html } from "@react-three/drei"

import Model from './Model';


const gltf_path = './models/alexa_echo/scene.gltf';

const Torus = ({ max_radius, scale_speed, position }) => {
    const [torusRadius, settorusRadius] = useState(0)
  
    useFrame((state, delta) => {
      if (torusRadius < max_radius) {
        settorusRadius((prev) => prev + scale_speed)
      }
      else {
        settorusRadius(0);
      }
    })
  
    return (
      <>
        <mesh position={position} rotation={[3.14 / 2, 0, 0]} >
          <torusGeometry args={[torusRadius, 1, 15, 100]} />
        </mesh>
      </>
    );
}

export default function Sensor({ layer_iot, position, currentSensor, enable_co2 }) {
    const max_radius = 75;
    const scale_speed = 1;
    const sensor_scale = [2, 2, 2]
    let temperature = currentSensor['temperature'];
    let humidity = currentSensor['humidity'];
    let co2;
    if (enable_co2 === true) {
      co2 = currentSensor['co2'];
    }
  
    if (layer_iot === true) {
        return (
            <>
                <mesh position={position} scale={sensor_scale} >
                    <Model position={[0, 0, 0]} rotation={[0, 0, 0]} scale={[0.35, 0.35, 0.35]} gltf_path={gltf_path} />
                    <meshStandardMaterial roughness={0.75} emissive="#404057" />
                </mesh>
                <Torus max_radius={max_radius} scale_speed={scale_speed} position={position} />
                <group position={position}>
                    <Html distanceFactor={400}>
                    <div className="content-sensor">
                        <p>
                            <b>Temp</b>:{temperature.toFixed(1)}C<br></br>
                            <b>Humid</b>:{humidity.toFixed(1)}%
                            {enable_co2 && (
                              <>
                                <br></br>
                                <b>CO2</b>:{co2.toFixed(0)}ppm
                              </>
                            )}
                        </p>
                    </div>
                    </Html>
                </group>
            </>
        );
    }
    else {
        return (<></>);
    }
}

useGLTF.preload(gltf_path);
