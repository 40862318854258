import React from 'react'
import { useGLTF } from "@react-three/drei";

import Model from './Model';


const gltf_paths = [
    './models/snow_loop_1/scene.gltf',  // fast animation, too small item
    './models/snow_loop_2/scene.gltf',  // slow animation, too small item
    './models/snow_loop_3/scene.gltf'  // normal speed, dense items (preferred)
]


export default function SnowFallLoop({ position, rotation, scale }) {

    return (
        <>
            <mesh position={position} rotation={rotation} scale={scale} >
                <Model gltf_path={gltf_paths[2]} enable_animation={true} />
            </mesh>
        </>
    );
}

useGLTF.preload(gltf_paths[0]);
useGLTF.preload(gltf_paths[1]);
useGLTF.preload(gltf_paths[2]);
