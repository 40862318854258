import React, { useRef } from 'react'
import { useGLTF } from "@react-three/drei";
import { useFrame } from '@react-three/fiber';

import Model from './Model';


const gltf_paths = ['./models/person_1/scene.gltf', 
                    './models/person_2/scene.gltf', 
                    './models/person_3/scene.gltf', 
                    './models/person_4/scene.gltf'];


export default function Persons({ layer_person, currentPosition }) {
    if (layer_person === true) {
        return (
          <>
            {currentPosition['coords'].map((personPosition) => {
              return (
                <Person key={personPosition.id} person_id={personPosition.id} x={personPosition.x} z={personPosition.z} />
              )
            })}
          </>
        );
      }
      else {
        return (<></>);
    }
}

const Person = ({ person_id, x, z }) => {
    const person_y = {
      '1': 33,
      '2': 35,
      '3': 25,
      '4': 30
    }
    const person_rotation_x = {
      '1': -3.14 / 10,
      '2': -3.14 / 15,
      '3': -3.14 / 7.5,
      '4': -3.14 / 7.5
    }
  
    let y = person_y[person_id];
    let rotation_x = person_rotation_x[person_id];
  
    const ref = useRef()
    let time = Math.random() * 2;
    useFrame((state, delta) => {
      time += 0.08;
      ref.current.position.y += Math.sin(time) * 0.2;
    })

    return (
      <mesh ref={ref} position={[x, y, z]} rotation={[rotation_x, 0, 0]} scale={[32, 32, 32]} >
        <Model position={[0, 0, 0]} rotation={[0, 0, 0]} scale={[1, 1, 1]} gltf_path={gltf_paths[person_id-1]} />
      </mesh>
    );
}

useGLTF.preload(gltf_paths[0]);
useGLTF.preload(gltf_paths[1]);
useGLTF.preload(gltf_paths[2]);
useGLTF.preload(gltf_paths[3]);
