// ref: https://github.com/plotly/react-plotly.js
import Plot from 'react-plotly.js';
import React from 'react'


export default function PlotFigure({ heading, person_data, weather_data, energy_data, enable_ac_before, timestamp, enable_ac_after_mode, enable_ac_after_temp, ac_data, selectedTs }) {
    var data = [];
    var vline_datetime;

    if (enable_ac_after_temp != null && ac_data != null) {
        data = [
            {
                x: ac_data['timestamp'],
                y: ac_data['ac1_temp'],
                type: 'scatter',
                mode: 'lines',
                name: 'AC1',
                marker: {color: 'black'},
                line: { width: 2 }
            },
            {
                x: ac_data['timestamp'],
                y: ac_data['ac2_temp'],
                type: 'scatter',
                mode: 'lines+dash',
                name: 'AC2',
                marker: {color: 'blue'},
                line: { width: 2, dash: 'dot' }
            },
            {
                x: ac_data['timestamp'],
                y: ac_data['ac3_temp'],
                type: 'scatter',
                mode: 'lines',
                name: 'AC3',
                marker: {color: 'red'},
                line: { width: 2 }
            },
            {
                x: ac_data['timestamp'],
                y: ac_data['ac4_temp'],
                type: 'scatter',
                mode: 'lines+dash',
                name: 'AC4',
                marker: {color: 'green'},
                line: { width: 2, dash: 'dot' }
            },
            {
                x: ac_data['timestamp'],
                y: ac_data['ac5_temp'],
                type: 'scatter',
                mode: 'lines',
                name: 'AC5',
                marker: {color: 'orange'},
                line: { width: 2 }
            }
        ]
        vline_datetime = ac_data['timestamp'][selectedTs]
    }

    if (enable_ac_after_mode != null && ac_data != null) {
        data = [
            {
                x: ac_data['timestamp'],
                y: ac_data['ac1_mode'],
                type: 'scatter',
                mode: 'lines',
                name: 'AC1',
                marker: {color: 'black'},
                line: { width: 2 }
            },
            {
                x: ac_data['timestamp'],
                y: ac_data['ac2_mode'],
                type: 'scatter',
                mode: 'lines+dash',
                name: 'AC2',
                marker: {color: 'blue'},
                line: { width: 2, dash: 'dot' }
            },
            {
                x: ac_data['timestamp'],
                y: ac_data['ac3_mode'],
                type: 'scatter',
                mode: 'lines',
                name: 'AC3',
                marker: {color: 'red'},
                line: { width: 2 }
            },
            {
                x: ac_data['timestamp'],
                y: ac_data['ac4_mode'],
                type: 'scatter',
                mode: 'lines+dash',
                name: 'AC4',
                marker: {color: 'green'},
                line: { width: 2, dash: 'dot' }
            },
            {
                x: ac_data['timestamp'],
                y: ac_data['ac5_mode'],
                type: 'scatter',
                mode: 'lines+dash',
                name: 'AC5',
                marker: {color: 'orange'},
                line: { width: 2, dash: 'dot' }
            }
        ]
        vline_datetime = ac_data['timestamp'][selectedTs]
    }

    if (enable_ac_before != null && timestamp != null) {
        let controls_set1 = Array(12).fill('off').concat(Array(120).fill('cool').concat(Array(12).fill('off')))
        let controls_set2 = Array(12).fill('cool').concat(Array(48).fill('off').concat(Array(108).fill('cool')))
        data = [
            {
                x: timestamp,
                y: controls_set1,
                type: 'scatter',
                mode: 'lines',
                name: 'AC1, AC3, AC5',
                marker: {color: 'black'},
                line: { width: 2 }
            },
            {
                x: timestamp,
                y: controls_set2,
                type: 'scatter',
                mode: 'lines+dash',
                name: 'AC2, AC4',
                marker: {color: 'red'},
                line: { width: 2, dash: 'dot' }
            }
        ]
        vline_datetime = timestamp[selectedTs]
    }

    if (person_data != null) {
        data = [
            {
                x: person_data['timestamp'],
                y: person_data['people_occupancy'],
                type: 'scatter',
                mode: 'lines',
                name: 'People Occupant',
                marker: {color: 'black'},
                line: { width: 3 }
            }
        ]
        vline_datetime = person_data['timestamp'][selectedTs]
    }
    if (energy_data != null && weather_data != null) {
        data = [
            {
                x: energy_data['timestamp'],
                y: energy_data['baseline_power'],
                type: 'scatter',
                mode: 'lines',
                name: 'Baseline Power',
                marker: {color: 'red'},
                line: { width: 3 }
            },
            {
                x: energy_data['timestamp'],
                y: energy_data['power'],
                type: 'scatter',
                mode: 'lines',
                name: 'Current Power',
                marker: {color: 'green'},
                line: { width: 3 }
            },
            {
                x: weather_data['timestamp'],
                y: weather_data['temperature'],
                type: 'scatter',
                mode: 'lines+dash',
                yaxis: 'y2',
                name: 'Temperature (C)',
                marker: {color: 'blue'},
                line: { width: 2, dash: 'dot' }
            }
        ]
        vline_datetime = energy_data['timestamp'][selectedTs]
    }

    // set custom config for legend component
    var legend_config = (energy_data && {
        showlegend: true,
        legend: {
            yanchor: "top",
            y: 0.95,
            xanchor: "left",
            x: 0.05
        }
    }) ||
    ((enable_ac_before || enable_ac_after_mode || enable_ac_after_temp) && {
        showlegend: true,
        legend: {
            yanchor: "bottom",
            y: 1,
            xanchor: "right",
            x: 1,
            orientation: "h"
        }
    })

    var yaxis_config = (energy_data && weather_data && {
        yaxis: { title: 'Power (kW)' },
        yaxis2: {
            title: 'Outdoor Temperature (C)',
            anchor: 'free',
            overlaying: 'y',
            side: 'right',
            position: 1
        }
    })
    var margin_r_config = (energy_data && weather_data) ? 40:0
    var margin_top_config = (enable_ac_before || enable_ac_after_mode || enable_ac_after_temp|| ac_data != null) ? 50:0

    var plot_height = (enable_ac_before || enable_ac_after_mode || enable_ac_after_temp || ac_data != null) ? 200:290

    return (
        <div>
            <p>
                <b style={{ fontSize: '15pt' }}>{heading}</b><br/>
                {/* <span style={{ fontSize: '10pt' }}>(dashboard link)</span> */}
            </p>
            <Plot
                data={data}
                layout={{
                    ...legend_config,
                    ...yaxis_config,
                    margin: { l: 30, r: margin_r_config, b: 20, t: margin_top_config, autoexpand: true },
                    height: plot_height,
                    shapes: [
                        {
                            type: 'line',
                            yref: 'paper',
                            x0: vline_datetime,
                            y0: 0.05,
                            x1: vline_datetime,
                            y1: 1,
                            line:{
                                color: 'lightgrey',
                                width: 2
                            }
                        }
                    ]
                }}
                style={{ width: "95%", paddingTop: '4.5px' }}
            />
        </div>
    );
}
