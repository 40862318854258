import React from 'react'
import { useGLTF, Html, Text } from "@react-three/drei";

import Model from './Model';
import SnowflakeIcon from './SnowflakeIcon';
import FanIcon from './FanIcon';
import SnowFallLoop from './SnowFall'


const gltf_paths = ['./models/air_conditioner/scene1.gltf',
                    './models/air_conditioner/scene2.gltf',
                    './models/air_conditioner/scene3.gltf',
                    './models/air_conditioner/scene4.gltf',
                    './models/air_conditioner/scene5.gltf'];

export default function AC({ ac_id, layer_ai, position, rotation, currentAC }) {
    let mode = currentAC['mode']
    let fan_level = currentAC['fan']
    let room_temperature = currentAC['room_temperature']
    let set_temperature = currentAC['set_temperature']
    let source = currentAC['source']
    let trigger = currentAC['trigger']

    // TODO: set color for `dry` mode
    let color_hex = null;
    if (mode === 'fan') {
        // color_hex = 0x00CD56;  // green
        color_hex = 0xFFD21B;  // yellow
    }
    else if (mode === 'cool') {
        color_hex = 0x3594FF;
    }
    else {  // `off` or other modes
        color_hex = 0x1B1212;
    }

    if (layer_ai === true) {
        return (
            <>
                <Model position={position} rotation={rotation} scale={[6, 6, 6]} gltf_path={gltf_paths[ac_id-1]} color_hex={color_hex} />
                <ModeCustom ac_id={ac_id} mode={mode} position={position} rotation={rotation} fan_level={fan_level} set_temperature={set_temperature} />

                {/* <group position={position}>
                    <Html distanceFactor={500}>
                    <div className="content-ac">
                        <Message mode={mode} set_temperature={set_temperature} fan={fan} />
                    </div>
                    </Html>
                </group> */}
            </>
        );
    }
    else {
        return (<></>);
    }
}

useGLTF.preload(gltf_paths[0]);
useGLTF.preload(gltf_paths[1]);
useGLTF.preload(gltf_paths[2]);
useGLTF.preload(gltf_paths[3]);
useGLTF.preload(gltf_paths[4]);

const Message = ({ mode, set_temperature, fan }) => {
    if (mode === 'off') {
        return (
            <p>
                <b>Mode</b>:{mode}
            </p>
        );
    }
    else if (mode === 'fan') {
        return (
            <p>
                <b>Mode</b>:{mode}<br></br>
                <b>Fan</b>:{fan}
            </p>
        );
    }
    else {
        return (
            <p>
                <b>Mode</b>:{mode}<br></br>
                <b>SetTemp</b>:{set_temperature}<br></br>
                <b>Fan</b>:{fan}
            </p>
        );
    }
}

const ModeCustom = ({ ac_id, mode, position, rotation, fan_level, set_temperature }) => {
    if (mode === 'fan') {
        return (
            <>
                <FanIcon position={[position[0], position[1]+50, position[2]]} rotation={rotation} />
                <ACText message={`Fan: ${fan_level}`} position={position} rotation={rotation} />
            </>
        );
    }
    else if (mode === 'cool') {
        return (
            <>
                <SnowflakeIcon position={[position[0], position[1]+60, position[2]]} rotation={rotation} />
                <SnowFallSelection ac_id={ac_id} />
                <ACText message={`Cool: ${set_temperature.toFixed(1)} C`} position={position} rotation={rotation} />
            </>
        );
    }
    else if (mode === 'off') {
        return (
            <>
                <ACText message="Off" position={position} rotation={rotation} />
            </>
        );
    }
    else {
        return (<></>);
    }
}

const SnowFallSelection = ({ ac_id }) => {
    var positions = [
        [-110, 40, -220],
        [70, 40, -220],
        [140, 40, -140],
        [140, 40, -40],
        [140, 40, 60]
    ]
    var rotations = [
        [0, 0, 0],
        [0, 0, 0],
        [0, -3.14/2, 0],
        [0, -3.14/2, 0],
        [0, -3.14/2, 0],
    ]

    return (
        <>
            <SnowFallLoop position={positions[ac_id-1]} rotation={rotations[ac_id-1]} scale={[40, 40, 40]} />
        </>
    );
}

const ACText = ({ message, position, rotation }) => {
    var _position = position;
    if (rotation[1] === 0) {
        _position = [position[0]+20, position[1]+50, position[2]];
    }
    else {
        _position = [position[0], position[1]+50, position[2]+20];
    }

    return (
        <>
            <Text
                position={_position}
                scale={[100, 100, 100]}
                rotation={rotation}
                color="white" // default
                anchorX="center" // default
                anchorY="middle" // default
            >
                {message}
            </Text>
        </>
    );
}