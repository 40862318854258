import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Navbar, Form, Button, Card } from 'react-bootstrap';
import RangeSlider from 'react-bootstrap-range-slider';
import { FaPlay, FaPause } from 'react-icons/fa';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';

import './App.css';
import Scene from './components/Scene';
import PlotFigure from './components/CustomPlot';
import GaugeFigure from './components/GaugeFigure';


const site_name = 'lotus-lasalle'  // 'shell-select'
const date = '2022-11-21'  // '2022-10-21'
var person_data = require(`./data/${site_name}/${date}/person.json`)
var weather_data = require(`./data/${site_name}/${date}/weather.json`)
var baseline_energy_data = require(`./data/${site_name}/${date}/ac_energy_baseline.json`)
var energy_data = require(`./data/${site_name}/${date}/ac_energy_current.json`)
var sensor_data = require(`./data/${site_name}/${date}/sensor1.json`)

var ac1_data = require(`./data/${site_name}/${date}/ac1.json`)
var ac2_data = require(`./data/${site_name}/${date}/ac2.json`)
var ac3_data = require(`./data/${site_name}/${date}/ac3.json`)
var ac4_data = require(`./data/${site_name}/${date}/ac4.json`)
var ac5_data = require(`./data/${site_name}/${date}/ac5.json`)

// preprocess JSON data
const PersonInfos = {
  'timestamp': [],
  'people_occupancy': []
}
for (let i = 0; i < person_data.length; i++) {
  let _data = person_data[i];
  let timestamp = _data['timestamp']
  let people_occupancy = (_data['objects']).length
  PersonInfos['timestamp'].push(timestamp)
  PersonInfos['people_occupancy'].push(people_occupancy)
}

const WeatherInfos = {
  'timestamp': [],
  'temperature': [],
  'humidity': [],
  'wx_phrase': []
}
for (let i = 0; i < weather_data.length; i++) {
  let _data = weather_data[i]
  let timestamp = _data['timestamp']
  let temperature = _data['temp']
  let humidity = _data['rh']
  let wx_phrase = _data['wx_phrase']
  WeatherInfos['timestamp'].push(timestamp)
  WeatherInfos['temperature'].push(temperature)
  WeatherInfos['humidity'].push(humidity)
  WeatherInfos['wx_phrase'].push(wx_phrase)
}

const EnergyInfos = {
  'timestamp': [],
  'energy': [],
  'baseline_energy': [],
  'power': [],
  'baseline_power': [],
  'carbon_emission': []
}
for (let i = 0; i < baseline_energy_data.length; i++) {
  let _baseline_data = baseline_energy_data[i];
  let _current_data = energy_data[i];
  let timestamp = _current_data['timestamp']
  let current_energy = _current_data['energy']
  let carbon_emission = current_energy * 0.5821  // unit: kg-CO2e
  let current_power = _current_data['power']
  let baseline_energy = _baseline_data['energy']
  let baseline_power = _baseline_data['power']
  EnergyInfos['timestamp'].push(timestamp)
  EnergyInfos['energy'].push(current_energy)
  EnergyInfos['power'].push(current_power)
  EnergyInfos['carbon_emission'].push(carbon_emission)
  EnergyInfos['baseline_energy'].push(baseline_energy)
  EnergyInfos['baseline_power'].push(baseline_power)
}

const ACInfos = {
  'timestamp': [],
  'ac1_mode': [],
  'ac2_mode': [],
  'ac3_mode': [],
  'ac4_mode': [],
  'ac5_mode': [],
  'ac1_temp': [],
  'ac2_temp': [],
  'ac3_temp': [],
  'ac4_temp': [],
  'ac5_temp': [],
}
for (let i = 0; i < ac1_data.length; i++) {
  let timestamp = ac1_data[i]['timestamp']
  ACInfos['timestamp'].push(timestamp)
  
  ACInfos['ac1_mode'].push(ac1_data[i]['mode'])
  ACInfos['ac2_mode'].push(ac2_data[i]['mode'])
  ACInfos['ac3_mode'].push(ac3_data[i]['mode'])
  ACInfos['ac4_mode'].push(ac4_data[i]['mode'])
  ACInfos['ac5_mode'].push(ac5_data[i]['mode'])

  ACInfos['ac1_temp'].push((ac1_data[i]['set_temperature'] != -1) ? ac1_data[i]['set_temperature'] : null)
  ACInfos['ac2_temp'].push((ac2_data[i]['set_temperature'] != -1) ? ac2_data[i]['set_temperature'] : null)
  ACInfos['ac3_temp'].push((ac3_data[i]['set_temperature'] != -1) ? ac3_data[i]['set_temperature'] : null)
  ACInfos['ac4_temp'].push((ac4_data[i]['set_temperature'] != -1) ? ac4_data[i]['set_temperature'] : null)
  ACInfos['ac5_temp'].push((ac5_data[i]['set_temperature'] != -1) ? ac5_data[i]['set_temperature'] : null)
}

const zeroPad = (num, places) => String(num).padStart(places, '0')
var VideoTimestamps = [];
for (let hour=0; hour<=23; hour++) {
  for (let minute=0; minute<=50; minute+=10) {
    VideoTimestamps.push(`${zeroPad(hour, 2)}:${zeroPad(minute, 2)}`)
  }
}

function range(startAt, endAt) {
  let size = endAt - startAt;
  return [...Array(size).keys()].map(i => i + startAt);
}

function App() {
  const videoName = '2022-10-21'
  const disableColor = '#888888'
  const enableColor = '#000000'

  const [personState, setPersonState] = useState(true)
  const [heatmapState, setHeatmapState] = useState(true)
  const [iotState, setiotState] = useState(true)
  const [aiState, setaiState] = useState(true)
  
  const [advanceState, setadvanceState] = useState(true)
  const [advanceColState, setadvanceColState] = useState(6)
  const [advanceHeightState, setadvanceheightState] = useState(65)
  const [advanceGraphState, setadvanceGraphState] = useState(true)
  const [advanceTimelineState, setadvanceTimelineState] = useState(false)

  const [sliderMin, setSliderMin] = useState(0)
  const [sliderMax, setSliderMax] = useState(143)
  const [playIconColor, setPlayIconColor] = useState(disableColor)
  const [pauseIconColor, setPauseIconColor] = useState(disableColor)
  const [iconDisplay, setIconDisplay] = useState(true)
  const [_, setIntervalLoop] = useState()
  const [sliderLabel, setSliderLabel] = useState()
  const [selectedTs, setSelectedTs] = useState(sliderMin)
  const [timestampMod, setTimestampMod] = useState(18)

  const [currentCO2, setCurrentCO2] = useState(sensor_data[selectedTs]['co2'])

  function handleCheckboxChange(event) {
    const target = event.target
    const checked = target.checked
    const name = target.name
    const value = target.value

    let name_split = name.split("/");
    let feature_name = name_split[name_split.length - 1]

    if (feature_name === "person") {
      setPersonState(checked);
    }
    else if (feature_name === "heatmap") {
      setHeatmapState(checked);
    }
    else if (feature_name === "iot") {
      setiotState(checked);
    }
    else if (feature_name === "ai") {
      setaiState(checked);
    }
    else if (feature_name === "advance") {
      setadvanceState(checked);
      setadvanceColState(checked ? 6 : 10);
      setadvanceheightState(checked ? 65 : 80);
      setadvanceGraphState(true)
        setadvanceTimelineState(false)
    }
    else if (feature_name === "date_selection") {
      if (value === "whole day") {
        setSliderMin(0)
        setSliderMax(143)
        setTimestampMod(18)
      }
      else if (value === "morning") {
        setSliderMin(30)
        setSliderMax(72)
        setTimestampMod(6)
      }
      else if (value === "afternoon") {
        setSliderMin(72)
        setSliderMax(108)
        setTimestampMod(6)
      }
      else if (value === "evening") {
        setSliderMin(108)
        setSliderMax(143)
        setTimestampMod(6)
      }
    }
    else if (feature_name === "advance_mode_selection") {
      if (value === "graph") {
        setadvanceGraphState(true)
        setadvanceTimelineState(false)
      }
      else if (value === "timeline") {
        setadvanceGraphState(false)
        setadvanceTimelineState(true)
      }
    }
  }

  const triggerTime = () => {
    const i = setInterval(() => {
      setSelectedTs((prev) => {
        if (prev < sliderMax) {
          return parseInt(prev) + 1;
        } 
        else {
          // clearTime();
          // setIconDisplay(true);
          return sliderMin;
        }
      });
    }, 300)
    setIntervalLoop(i)
  }

  const clearTime = () => {
    setIntervalLoop((prev) => {
      clearInterval(prev)
      return null
    })
  }

  useEffect(() => {
    let _sliderLabel = [];
    range(sliderMin, sliderMax+1).forEach((item)=>{
      if (item % timestampMod == 0) {
        _sliderLabel.push(<span style={{ fontSize: '10pt' }}>{VideoTimestamps[item]}</span>)
      }
    })
    if (sliderMax === 143) {
      _sliderLabel.push(<span style={{ fontSize: '10pt' }}>24:00</span>)
    }
    setSliderLabel(_sliderLabel)
    setSelectedTs(sliderMin)
  }, [sliderMin, sliderMax, timestampMod]);

  useEffect(() => {
    setCurrentCO2(sensor_data[selectedTs]['co2'])
  }, [selectedTs]);

  return (
    <>
      <Container fluid>
        <Navbar bg="light" expand="lg">
          <Navbar.Brand><b>AltoTech - Autopilot Dashboard</b></Navbar.Brand>
          <Navbar.Collapse className="justify-content-end">
            <Navbar.Text>
              {/* (logo) */}
            </Navbar.Text>
          </Navbar.Collapse>
        </Navbar>
        <Row>
          {/* Sidebar */}
          <Col xs={2} style={{ marginTop: '20px' }} >
            <Form style={{ marginLeft: '10px', marginRight: '10px' }}>
              <Form.Group>
                <Form.Label><b>Site Selection</b></Form.Label>
                <Form.Select id="form/site_selection">
                  <option value="Lotus Lasalle">Lotus Lasalle</option>
                  <option value="AltoTech Office">AltoTech Office</option>
                </Form.Select>
              </Form.Group>
              <br></br>
              <Form.Group>
                <Form.Label><b>Data Selection</b></Form.Label>
                <p style={{ fontSize: '12pt' }}>2022-10-21</p>
                <Form.Check
                  defaultChecked
                  type="radio"
                  value="whole day"
                  label="whole day"
                  name="form/date_selection"
                  onChange={handleCheckboxChange}
                />
                <Form.Check
                  type="radio"
                  value="morning"
                  label="morning"
                  name="form/date_selection"
                  onChange={handleCheckboxChange}
                />
                <Form.Check
                  type="radio"
                  value="afternoon"
                  label="afternoon"
                  name="form/date_selection"
                  onChange={handleCheckboxChange}
                />
                <Form.Check
                  type="radio"
                  value="evening"
                  label="evening"
                  name="form/date_selection"
                  onChange={handleCheckboxChange}
                />
              </Form.Group>
              <br></br>
              <Form.Group>
                <Form.Label><b>Features Selection</b></Form.Label>
                <Form.Check
                  defaultChecked
                  type="checkbox"
                  label="Person Information"
                  name="form/feature_selection/person"
                  onChange={handleCheckboxChange}
                />
                <Form.Check
                  defaultChecked
                  type="checkbox"
                  label="Occupancy Heatmap"
                  name="form/feature_selection/heatmap"
                  onChange={handleCheckboxChange}
                />
                <Form.Check
                  defaultChecked
                  type="checkbox"
                  label="IoT Sensors"
                  name="form/feature_selection/iot"
                  onChange={handleCheckboxChange}
                />
                <Form.Check
                  defaultChecked
                  type="checkbox"
                  label="AI Controls & A/C"
                  name="form/feature_selection/ai"
                  onChange={handleCheckboxChange}
                />
                <Form.Check
                  disabled
                  type="checkbox"
                  label="Outdoor Weather"
                  name="form/feature_selection/outdoor"
                  onChange={handleCheckboxChange}
                />
                <Form.Check
                  disabled
                  type="checkbox"
                  label="Solar PV"
                  name="form/feature_selection/solar"
                  onChange={handleCheckboxChange}
                />
                <Form.Check
                  disabled
                  type="checkbox"
                  label="Tenant Feedback"
                  name="form/feature_selection/tenant"
                  onChange={handleCheckboxChange}
                />
                <Form.Check
                  disabled
                  type="checkbox"
                  label="Carbon Emission"
                  name="form/feature_selection/carbon"
                  onChange={handleCheckboxChange}
                />
              </Form.Group>
              <br></br>
              <Form.Group>
                <Form.Check
                  defaultChecked
                  type="switch"
                  label="Advance Mode"
                  name="form/feature_selection/advance"
                  onChange={handleCheckboxChange}
                />
              </Form.Group>
              {advanceState && (
                <Form.Group>
                  <Form.Check
                    defaultChecked
                    type="radio"
                    label="Graphs"
                    value="graph"
                    name="form/advance_mode_selection"
                    onChange={handleCheckboxChange}
                  />
                  <Form.Check
                    type="radio"
                    label="Timeline"
                    value="timeline"
                    name="form/advance_mode_selection"
                    onChange={handleCheckboxChange}
                  />
                </Form.Group>
              )}
            </Form>
            <br></br>
            <Button variant="secondary">More info</Button>
          </Col>

          {/* 3D models */}
          <Col xs={advanceColState} style={{ marginTop: '20px' }} >
            <div style={{ width: '100%', height: `calc(${advanceHeightState}vh - 75px)` }} >
              <Scene selectedTs={selectedTs} layer_person={personState} layer_heatmap={heatmapState} layer_iot={iotState} layer_ai={aiState} />
              <div style={{ paddingTop: '15px' }}>
                <p style={{ display: 'flex', justifyContent: 'space-between', margin: '0px' }}>
                  {sliderLabel}
                </p>
                <RangeSlider
                  min={sliderMin}
                  max={sliderMax}
                  value={selectedTs}
                  onChange={changeEvent => setSelectedTs(changeEvent.target.value)}
                  variant='dark'
                  tooltip='off'
                />
                <p>
                  {iconDisplay && (
                    <FaPlay
                      onClick={() => {
                        triggerTime();
                        setIconDisplay(false);
                      }}
                      onMouseEnter={() => setPlayIconColor(enableColor)}
                      onMouseLeave={() => setPlayIconColor(disableColor)}
                      style={{ 'color': playIconColor }}
                    />
                  )}
                  {!iconDisplay && (
                    <FaPause
                      onClick={() => {
                        clearTime();
                        setIconDisplay(true);
                      }}
                      onMouseEnter={() => setPauseIconColor(enableColor)}
                      onMouseLeave={() => setPauseIconColor(disableColor)}
                      style={{ 'color': pauseIconColor }}
                    />
                  )}
                  &emsp;{`${videoName} (${VideoTimestamps[selectedTs]})`}
                </p>
              </div>
              {advanceState && (
                <div style={{ width: '100%' }}>
                  <Row>
                    <Col sm={4}>
                      <Card>
                        <div style={{ marginTop: '15px', marginBottom: '0px', marginLeft: '5px', marginRight: '5px' }}>
                          <GaugeFigure heading="Energy Efficiency (kWh/m2·y)" enable_energy={true} />
                        </div>
                      </Card>
                    </Col>
                    <Col sm={4}>
                      <Card>
                        <div style={{ marginTop: '15px', marginBottom: '0px', marginLeft: '5px', marginRight: '5px' }}>
                          <GaugeFigure heading="Current IAQ (CO2: ppm)" enable_iaq={true} currentCO2={currentCO2} />
                        </div>
                      </Card>
                    </Col>
                    <Col sm={4}>
                      <Card>
                        <div style={{ marginTop: '15px', marginBottom: '0px', marginLeft: '5px', marginRight: '5px' }}>
                          <GaugeFigure heading="CO2 Emission (kg-CO2eq/day)" enable_co2eq={true} />
                        </div>
                      </Card>
                    </Col>
                  </Row>
                </div>
              )}
            </div>
          </Col>

          {/* Graphs */}
          {advanceState && (
            advanceGraphState && (
              <Col xs={4} style={{ height: 'calc(100vh - 76px)', overflowX: 'scroll', marginTop: '20px' }} >
                <Row style={{ marginBottom: '5px' }} >
                  <Col>
                    <Card style={{ height: '100%' }} >
                      <div style={{ margin: '10px', marginBottom: '0px' }}>
                        <p style={{ fontSize: '15pt' }}><b>Energy Saved</b></p>
                        <p style={{ fontSize: '30pt', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          <b style={{ color: 'green' }}>28.76</b>%
                        </p>
                      </div>
                    </Card>
                  </Col>
                  <Col>
                    <Card>
                      <div style={{ margin: '10px', marginBottom: '0px' }}>
                        <p style={{ fontSize: '15pt' }}><b>Carbon Emission Reduced</b></p>
                        <p style={{ fontSize: '12pt', marginLeft: '10px' }}>
                          daily: <b style={{ fontSize: '20pt', color: 'green' }}>22.3</b> kg-CO2eq<br></br>
                          annually: <b style={{ fontSize: '20pt', color: 'green' }}>8.515</b> ton-CO2eq
                        </p>
                      </div>
                    </Card>
                  </Col>
                </Row>

                {/* <Card style={{ marginBottom: '5px', height: '400px' }}>
                  <div style={{ margin: '10px', marginRight: '0px' }} >
                    <PsychrometricChart heading="Psychrometric Chart" />
                  </div>
                </Card> */}

                <Card style={{ marginBottom: '5px' }}>
                  <div style={{ margin: '10px', marginRight: '0px' }} >
                    <PlotFigure heading="HVAC Power (kW)" energy_data={EnergyInfos} weather_data={WeatherInfos} selectedTs={selectedTs} />
                  </div>
                </Card>
                
                <Card>
                  <div style={{ margin: '10px' }} >
                    <PlotFigure heading="People Occupant" person_data={PersonInfos} selectedTs={selectedTs} />
                  </div>
                </Card>
              </Col>
            ) ||
            advanceTimelineState && (
              <Col xs={4} style={{ height: 'calc(100vh - 76px)', overflowX: 'scroll', marginTop: '20px' }} >
                <Card style={{ marginBottom: '5px' }}>
                  <div style={{ margin: '10px', marginRight: '0px' }} >
                    <PlotFigure heading="Before: Schedule Controls" enable_ac_before={true} timestamp={WeatherInfos['timestamp']} selectedTs={selectedTs} />
                  </div>
                </Card>
                <Card style={{ marginBottom: '5px' }}>
                  <div style={{ margin: '10px', marginRight: '0px' }} >
                    <PlotFigure heading="After: Dynamic Mode Controls" enable_ac_after_mode={true} ac_data={ACInfos} selectedTs={selectedTs} />
                  </div>
                </Card>
                <Card style={{ marginBottom: '5px' }}>
                  <div style={{ margin: '10px', marginRight: '0px' }} >
                    <PlotFigure heading="After: Dynamic Temperature Controls" enable_ac_after_temp={true} ac_data={ACInfos} selectedTs={selectedTs} />
                  </div>
                </Card>
              </Col>
            )
          )}
        </Row>
      </Container>
    </>
  );
}

export default App;
